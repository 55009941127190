import React, { useState, useContext } from 'react';
import axios from 'axios';
import { navigate, Link } from 'gatsby';

import SEO from '../blocks/layout/seo';
import { Input, Button } from 'antd';
import { FiSearch } from 'react-icons/fi';
import { FaToggleOff } from 'react-icons/fa';
import { GrPlay } from 'react-icons/gr';
import { GiClick } from 'react-icons/gi';
import AppContainer from '../blocks/layout';

import { addons } from '../data/addons';
import { Firebase } from '../config/firebase';
import {
      GlobalDispatchContext,
      GlobalStateContext,
} from '../context/GlobalContextProvider';

import '../styles/marketplace.scss';

// markup
const Addons = () => {
      const [addonsList, setAddonsList] = useState(addons);
      const [addingLoader, setAddingLoader] = useState(false);
      const [addingAppId, setAddingAppId] = useState('');
      const dispatch = useContext(GlobalDispatchContext);
      const state = useContext(GlobalStateContext);

      const { projects, selectedProjectId } = state;

      const filterAddonsList = (e) => {
            const addonsList = [];
            const targetValue = e.target.value.toLocaleLowerCase();
            if (targetValue !== '') {
                  addons.forEach((addon) => {
                        const isMatch = addon.name
                              .toLocaleLowerCase()
                              .search(targetValue);
                        if (isMatch > -1) addonsList.push(addon);
                  });
                  setAddonsList(addonsList);
            } else {
                  setAddonsList(addons);
            }
      };

      const isAddonActivated = (id) => {
            const isExist = projects
                  ?.find((p) => p._id == selectedProjectId)
                  ?.addons_list?.findIndex((x) => x.id === id);
            if (isExist > -1) {
                  return true;
            }
            return false;
      };

      return (
            <>
                  <SEO title="Addons Marketplace — NoCodeAPI" />
                  <AppContainer activeNav="addonsmarketplace">
                        <div className="page-content-header">
                              <h2>Addons</h2>
                              <p>
                                    Find addons & activate 
                              </p>
                              <Input
                                    placeholder="search addons"
                                    prefix={<FiSearch />}
                                    onChange={filterAddonsList}
                                    size="large"
                              />
                        </div>
                        {addonsList.length > 0 ? (
                              <div className="app-list">
                                    {addonsList.map((addon, key) => (
                                          <div className="app-card" key={key}>
                                                <div className="card-content">
                                                      <div className="app-name-logo">
                                                            <img
                                                                  src={`/logos/${addon.logo}`}
                                                                  alt={addon.name}
                                                                  height="30px"
                                                            />
                                                            <h3>{addon.name}</h3>
                                                      </div>
                                                      <p>{addon.description}</p>
                                                      {isAddonActivated(
                                                            addon.id
                                                      ) ? (
                                                            <Link
                                                                  to={`/dashboard/addons/${addon.id}`}
                                                            >
                                                                  <Button
                                                                        style={{
                                                                              fontSize: 13,
                                                                              fontWeight: 300,
                                                                        }}
                                                                        icon={
                                                                              <GrPlay />
                                                                        }
                                                                  >
                                                                        Use this Addon
                                                                  </Button>
                                                            </Link>
                                                      ) : (
                                                            <Link
                                                                  to={`/addons/plans/${addon.id}`}
                                                            >
                                                            <Button
                                                                  type="primary"
                                                                  style={{
                                                                        fontSize: 13,
                                                                        fontWeight: 300,
                                                                  }}
                                                                  loading={
                                                                        addingLoader &&
                                                                        addingAppId ===
                                                                        addon.id
                                                                  }
                                                                  icon={
                                                                        <FaToggleOff />
                                                                  }
                                                            >
                                                                  Purchase
                                                            </Button>
                                                            </Link>
                                                      )}
                                                </div>
                                          </div>
                                    ))}
                              </div>
                        ) : (
                              <div className="request-section">
                                    <p>
                                          <GiClick /> Can’t find an AddOns? Send us
                                          your request here --{' '}
                                          <b>
                                                <a
                                                      href="https://nocodeapi.com/contact"
                                                      target="_blank"
                                                >
                                                      Request New AddOns
                                                </a>
                                          </b>
                                     </p>
                              </div>
                        )}
                  </AppContainer>
            </>
      );
};

export default Addons;
