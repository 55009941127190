export const addons = [
    {
          name: 'Workflows',
          id: 'workflows',
          type: 'addons',
          logo: 'addons_workflows.png',
          description:
                'Use Workflows',
          published: true,
          category: 'Workflows',
          docSlug: 'workflows-api',
    },
];
